/* PRETTY STUFF */
body, html {
  background-color: #131f24 !important;
}
body {
  scroll-behavior: smooth;
  overflow-y: scroll;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important;  /* IE and Edge */
  color: white !important;
  cursor: default;
}
/* ::-webkit-scrollbar {
  display: none !important;
  width: none !important;
} */

* {
  font-weight: 300;
}

h1 {
  font-weight: 700;
  font-size: xx-large;
  margin-bottom: 20px;
}

h2 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: x-large;
}

h3 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: large;
}

/* up and down pulse */
@keyframes up-and-down {
  0% {
    transform: translateY(0);
    color: white;
  }
  50% {
    transform: translateY(-10px); /* Adjust the -10px value to control the movement range */
    /* Make it grow */
    transform: scale(1.1);
    color: #FF4B4B;
  }
  100% {
    transform: translateY(0);
  }
}

.slow-up-and-down {
  animation: up-and-down 2s ease-in-out infinite; /* Adjust 3s for speed */
}